import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';


import {AppComponent} from './app.component';
import {CoreModule} from './core/core.module';
import {AppRoutingModule} from './app-routing.module';
import {ElementModule} from './element/element.module';
import {HttpClientModule} from '@angular/common/http';
import {ScrewsService} from './element/screws/screws.service';
import {CustomReuseStrategy} from './core/CustomReuseStrategy';
import {RouteReuseStrategy} from '@angular/router';


@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserModule,
		CoreModule,
		HttpClientModule,
		AppRoutingModule,
		ElementModule
	],
	providers: [
		ScrewsService,
		{provide: RouteReuseStrategy, useClass: CustomReuseStrategy}
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
