import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Screw} from '../../model/screw';

import {HttpClient} from '@angular/common/http';

@Injectable()
export class ScrewsService {

	private apiUrl = 'assets/mock/screws.json';
	//private apiUrl = 'http://projekt3.jgz/api/screws';

	constructor(private http: HttpClient) {
	}

	getScrews(): Observable<Screw[]> {
		// return this.http.get(this.apiUrl).map((res) => res.json());
		return this.http.get<Screw[]>(this.apiUrl);
	}
}
