import {Component, OnInit} from '@angular/core';
import {ScrewsService} from './screws.service';
import {Screw} from '../../model/screw';
import {ActivatedRoute, RouteReuseStrategy} from '@angular/router';



@Component({
	selector: 'app-screws',
	templateUrl: './screws.component.html',
	styleUrls: ['./screws.component.less']
})
export class ScrewsComponent implements OnInit {

	type: string;
	screws: Screw[];

	constructor(private screwsService: ScrewsService, private route: ActivatedRoute, private reuse: RouteReuseStrategy) {
		this.route.params.subscribe(params => {
			this.type = params['type'];
		});

	}

	ngOnInit() {
		this.loadScrews();
	}

	loadScrews(): void {
		this.screwsService.getScrews().subscribe((screws) => {
			this.screws = screws;
			if (this.type) {
				this.filterTypes();
			}

			this.reuse.shouldDetach(this.route.snapshot);
		});
	}

	private filterTypes(): void {
		if (!this.type) {
			return;
		}
		this.screws = this.screws.filter((el) => {
			return this.type == el.type;
		});
	}
}
