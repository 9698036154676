import {NgModule} from '@angular/core';
import {RouterModule, Route} from '@angular/router';
import {StartComponent} from './element/start/start.component';
import {ScrewsComponent} from './element/screws/screws.component';
import {ContactComponent} from './element/contact/contact.component';

const APP_ROUTES: Route[] = [
	{path: '', pathMatch: 'full', redirectTo: 'start'},
	{path: 'start', component: <any>StartComponent},
	{path: 'kontakt', component: <any>ContactComponent},
	{path: 'screws', component: <any>ScrewsComponent},
	{path: 'screws/:type', component: <any>ScrewsComponent},
	{path: '**', component: <any>StartComponent}
];

@NgModule({
	imports: [
		RouterModule.forRoot(APP_ROUTES)
	],
	exports: [
		RouterModule
	]
})

export class AppRoutingModule {
}
