import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StartComponent} from './start/start.component';
import {ScrewsComponent} from './screws/screws.component';
import { ContactComponent } from './contact/contact.component';

@NgModule({
	imports: [
		CommonModule
	],
	declarations: [
		StartComponent,
		ScrewsComponent,
		ContactComponent
	],
	exports: [
		StartComponent
	],
	providers: []
})
export class ElementModule {
}
